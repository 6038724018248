<script setup>
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {queryClientWorkorder} from "../../api/workorder";
import {Progress} from "../../plugin/profile";
const urlParam = getUrlParam();
const router = useRouter();
const WorkorderList = ref([])
//查询客户下的工单列表
const queryClientWorkorderlist = () => {
  let clientCellphone = localStorage.getItem("createWorkorderPhone") || null
  if ( clientCellphone ){
    queryClientWorkorder({clientCellphone}).then(res => {
      let { records } = res.data
      if (records.length > 0) {
        WorkorderList.value = records
        console.log(records)
      }
    })
  }
};


// 待指派
// const onDispatchWorkorderClick = (id) => {
//   urlParam.workorderId = id;
//   router.push({path: "/unassigned-workorder", query: urlParam});
// };
//
// const onPrepareWorkorderClick = (id) => {
//   urlParam.workorderId = id;
//   router.push({path: "/prepare-workorder", query: urlParam});
// };
//
const onWorkorderItemClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/workorder", query: urlParam});
};





onMounted(() => {
  queryClientWorkorderlist()
})

</script>

<template>
 <div>
   <van-nav-bar fixed border left-arrow title="已报修工单" left-text="返回" @click-left="router.back"/>

   <div class="recordCord" >
     <div class="recordCord-item" v-for="(item,index) in WorkorderList" :key="index" @click="onWorkorderItemClick(item.id)">
      <div class="head">
        <span>工单编号：{{item.id}}</span>
        <spsn :class="item.progress === 1?'chens':item.progress === 13?'lvs':item.progress === 17?'lans':''">
          {{Progress[item.progress]}}
        </spsn>
      </div>
       <ul>
         <li><span>创建时间：</span><span>{{item.createTime}}</span></li>
         <li><span>联系人：</span><span>{{item.clientName}}</span></li>
         <li><span>联系电话：</span><span>{{item.clientCellphone}}</span></li>
       </ul>
     </div>
   </div>

 </div>
</template>

<style scoped>
.recordCord{
  background: #f5f5f5;
  padding-top: 60px;
}
.recordCord-item{
  background-color: #fff;
  width: 90%;
  margin:0px auto;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px #ddd solid;
  box-shadow: #adadad 0 0 7px;
}
.head{
  border-bottom: 1px #ddd solid;
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.head span{
  color: #666666;
}
.head .lans{
  color: #1f8be8;
}
.head .lvs{
  color: #61fd86;
}
.head .chens{
  color: #ffa500;
}
.recordCord-item ul{
  padding: 10px;
}
.recordCord-item ul li{
  height: 40px;
}
.recordCord-item ul li span{
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
</style>